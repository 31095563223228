import * as rt from "runtypes";

export const RtStatsObject = rt.Record({
  assists: rt.Number,
  boosts: rt.Number,
  dBNOs: rt.Number,
  dailyKills: rt.Number,
  dailyWins: rt.Number,
  damageDealt: rt.Number,
  days: rt.Number,
  headshotKills: rt.Number,
  heals: rt.Number,
  killPoints: rt.Number,
  kills: rt.Number,
  longestKill: rt.Number,
  longestTimeSurvived: rt.Number,
  losses: rt.Number,
  maxKillStreaks: rt.Number,
  mostSurvivalTime: rt.Number,
  rankPoints: rt.Number,
  rankPointsTitle: rt.String,
  revives: rt.Number,
  rideDistance: rt.Number,
  roadKills: rt.Number,
  roundMostKills: rt.Number,
  roundsPlayed: rt.Number,
  suicides: rt.Number,
  swimDistance: rt.Number,
  teamKills: rt.Number,
  timeSurvived: rt.Number,
  top10s: rt.Number,
  vehicleDestroys: rt.Number,
  walkDistance: rt.Number,
  weaponsAcquired: rt.Number,
  weeklyKills: rt.Number,
  weeklyWins: rt.Number,
  winPoints: rt.Number,
  wins: rt.Number,
});

export const RtStats = rt.Record({
  solo: RtStatsObject,
  "solo-fpp": RtStatsObject,
  duo: RtStatsObject,
  "duo-fpp": RtStatsObject,
  squad: RtStatsObject,
  "squad-fpp": RtStatsObject,
});
